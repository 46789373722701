:root {
  --background-rgb: 2 6 23;
  --background-light-rgb: 30 41 59;
  
  --border-rgb: 255 255 255;
  --border: 1px solid rgb(var(--border-rgb) / 20%);
  
  --hyperplexed-main-rgb: 41 121 255;
  --hyperplexed-main-light-rgb: 56 182 255;
  --hyperplexed-secondary-rgb: 42 252 152;
    
  --card-size: 480px;
  --font-size: 0.8rem;
  --logo-size: calc(var(--card-size) * 0.3);
}

.App {
  text-align: center;
}

*{
  font-family: Ubuntu Mono;
}